import React, { useState, useEffect } from "react"
import { animated, useSpring } from "react-spring"
import styled from 'styled-components'
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { colors } from "../theme";
import { navigate } from "gatsby";
import { useAuthenticationState } from "../providers/AuthenticationProvider";

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
`

const Curtains = styled.div`
  position: fixed;
  height: 110%;
  width: 110%;
  left: -5%;
  top: -5vh;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  > :nth-child(n) {
    height: 50%;
    width: 50%;
    background-color: tomato;
  }

  > :nth-child(2) {
    left: 50%;
  }
`

const Content = styled(animated.div)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    font-size: 41px;
    margin-bottom: 30px;
  }
  > :last-child {
    font-size: 20px;
    margin-top: 20px;
  }
`

const Avatar = styled.div`
  height: 190px;
  width: 190px;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`

const IndexPage = () => {
  const { data } = useQuery(gql`query { me { _id, name, avatar { url } }}`)
  const { isLoggedIn } = useAuthenticationState()
  
  const [isOpen, setIsOpen] = useState(false)
  const [user, setUser] = useState()

  const props = useSpring({
    xy: [
      isOpen ? window.innerWidth / 2 : 0,
      isOpen ? window.innerHeight / 2 : 0
    ],
    backgroundColor: isOpen ? colors.darkBlue : colors.indigo,
    scale: isOpen ? 1 : 0.5,
    contentOpacity: isOpen ? 1 : 0,
    config: { mass: 1, friction: 23 }
  })

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, 500)
  }, [])

  useEffect(() => {
    if (data && data.me) {
      setUser(data.me)
    }
    // eslint-disable-next-line
  }, [data && data.me])

  useEffect(() => {
    if (isOpen === false) return
    setTimeout(() => {
      if (isLoggedIn) {
        navigate('/home')
      } else {
        navigate('/login')
      }
      return null
    }, 1500)
    // eslint-disable-next-line
  }, [isOpen])


  return (
    <Wrapper
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      <Curtains>
        <animated.div
          style={{
            ...props,
            transform: props.xy.interpolate(
              (x, y) => `translate(${-x}px, ${-y}px)`
            )
          }}
        />
        <animated.div
          style={{
            ...props,
            transform: props.xy.interpolate(
              (x, y) => `translate(${x}px, ${-y}px)`
            )
          }}
        />
        <animated.div
          style={{
            ...props,
            transform: props.xy.interpolate(
              (x, y) => `translate(${-x}px, ${y}px)`
            )
          }}
        />
        <animated.div
          style={{
            ...props,
            transform: props.xy.interpolate(
              (x, y) => `translate(${x}px, ${y}px)`
            )
          }}
        />
      </Curtains>
      <Content
        style={{
          opacity: props.contentOpacity,
          transform: props.scale.interpolate(s => `scale(${s})`)
        }}
      >
        {
          user && <User>
            <div>Welcome back</div>
            <Avatar src={user.avatar && user.avatar.url} user={user} />
            <div>{user.name}</div>
          </User>
        }
      </Content>
    </Wrapper>
  )
}

const IndexPagePage = (props) => {
  if (typeof window === 'undefined') return null
  return <IndexPage {...props} />
}

export default IndexPagePage
